import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { scaleInOutAnimation } from 'src/app/animations/scale-in-out.animation';
import { hex2rgba } from 'src/app/shared/helpers/hex2rgba';

@Component({
  selector: 'app-widget-quick-line-value',
  templateUrl: './widget-quick-line-value.component.html',
  styleUrls: ['./widget-quick-line-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [scaleInOutAnimation]
})
export class WidgetQuickLineValueComponent {

  @Input() name: string;
  @Input() color: string;
  @Input() tooltip: string;
  @Input() icon: string;
  @Input() values: number[] = [];
  @Input() diffValues: number[] = [];
  @Input() labels: string[] = [];
  @Input() noDataText = 'N/A';

  hex2rgba = hex2rgba;

  constructor() { }
}

export interface ValueCard {
  name: string;
  icon: string;
  tooltip: string;
  color: string;
  values: (number | string)[];
  diffValues?: (number | string)[];
  labels: string[];
}
