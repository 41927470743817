import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BlobServiceClient } from '@azure/storage-blob';
import domtoimage from 'dom-to-image';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { BehaviorSubject, forkJoin, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoggedInUser } from '../auth/models/loggedInUser';
import { GoogleAnalyticsService } from '../shared/services/google-analytics.service';
import { LoadingOverlayService } from '../shared/services/loading-overlay.service';
import { LocalStorageService } from '../shared/services/local-storage.service';
import { ReportBlock } from './models/reportBlock';
import { ReportSlide } from './models/reportSlide';

@Injectable({
  providedIn: 'root'
})
export class EditorService {

  reportBlocks: ReportBlock[] = [];
  reportElementsInitialized = new BehaviorSubject(true);

  private blobServiceClient = BlobServiceClient.fromConnectionString(environment.storageAccounts.reportsStorageAccount.connectionString);

  get currentUserName() {
    return (JSON.parse(this.localStorageService.getItem('current_user', '{}')) as LoggedInUser)?.login;
  }

  private draftReportsContainerClient = this.blobServiceClient.getContainerClient(
    environment.storageAccounts.reportsStorageAccount.draftReportsContainer
  );

  constructor(
    private localStorageService: LocalStorageService,
    private snackbar: MatSnackBar,
    private loadingOverlayService: LoadingOverlayService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.initializeReportBlocks();
  }

  initializeReportBlocks() {
    const self = this;
    this.getReportBlocks().subscribe(blobResult => {
      const fr = new FileReader();
      fr.onload = function() {
        try {
          self.reportBlocks = JSON.parse(this.result as string);
          self.reportElementsInitialized.next(true);
        } catch (err) {
          console.log(err);
        }
      };
      fr.readAsText(blobResult);
    }, err => {
      console.log(err);
      this.reportBlocks = [];
    });
  }

  public addReportElement(querySelector: string, elementName: string, saveOption: 'domtoimg' | 'html2canvas' = 'domtoimg') {
    const domElement = document.querySelector(querySelector) as HTMLElement;
    const startTime = new Date().getTime();

    if (saveOption === 'html2canvas') {
      // workaround for svg elements export
      const svgElements = domElement.querySelectorAll('svg');
      svgElements.forEach((item) => {
        item.setAttribute('width', item.getBoundingClientRect().width.toString());
        item.setAttribute('height', item.getBoundingClientRect().height.toString());
        item.style.width = null;
        item.style.height = null;
      });

      html2canvas(domElement).then(image => {
        this.addToReportElements(image.toDataURL(), elementName);
        console.log(`Time elapsed: ${(new Date().getTime() - startTime) / 1000} seconds`);
      });
    } else {
      // workaround to avoid black background on exported element
      domElement.setAttribute('fill', '#fff');
      const elemBorderRadius = domElement.style.borderRadius;
      domElement.style.borderRadius = '0px';

      domtoimage.toJpeg(domElement).then(image => {
        this.addToReportElements(image, elementName);
        domElement.style.borderRadius = elemBorderRadius;
        console.log(`Time elapsed: ${(new Date().getTime() - startTime) / 1000} seconds`);
      });
    }
  }

  private addToReportElements(image: string, elementName: string) {
    this.reportBlocks.push({
      name: elementName,
      content: `<img src="${image}" style="max-width:100%; max-height:100%;" alt="pdf-report-element" />`
    });
    this.snackbar.open('Element has been added to the report.', '', {
      duration: 2000
    });
    this.saveReportBlocks(this.reportBlocks);
    this.googleAnalyticsService.emitGoogleAnalyticsEvent('exportElement', 'report', elementName, 1);
  }

  public clearReportElements() {
    this.reportBlocks = [];
  }

  getLastModifiedDate() {
    const blockBlobClient = this.draftReportsContainerClient.getBlockBlobClient(`report-${this.currentUserName}.json`);
    return forkJoin([blockBlobClient.getProperties()]).pipe(
      map(([res]) => res.lastModified)
    ).pipe(
      catchError(x => of(new Date()))
    );
  }

  getReportSlides() {
    const blockBlobClient = this.draftReportsContainerClient.getBlockBlobClient(`report-${this.currentUserName}.json`);
    return forkJoin([blockBlobClient.download(0)]).pipe(
      switchMap(([res]) => res.blobBody)
    ).pipe(
      catchError(x => of(new Blob()))
    );
  }

  async saveReportSlides(slides: ReportSlide[]) {
    const blockBlobClient = this.draftReportsContainerClient.getBlockBlobClient(`report-${this.currentUserName}.json`);
    const str = JSON.stringify(slides);
    const bytes = new TextEncoder().encode(str);
    const blob = new Blob([bytes], {
      type: 'application/json;charset=utf-8'
    });
    const res = await blockBlobClient.uploadData(blob);
    return res;
  }

  getReportBlocks() {
    const blockBlobClient = this.draftReportsContainerClient.getBlockBlobClient(`blocks-${this.currentUserName}.json`);
    return forkJoin([blockBlobClient.download(0)]).pipe(
      switchMap(([res]) => res.blobBody)
    );
  }

  async saveReportBlocks(reportContent: ReportBlock[]) {
    const blockBlobClient = this.draftReportsContainerClient.getBlockBlobClient(`blocks-${this.currentUserName}.json`);
    const str = JSON.stringify(reportContent);
    const bytes = new TextEncoder().encode(str);
    const blob = new Blob([bytes], {
      type: 'application/json;charset=utf-8'
    });
    const res = await blockBlobClient.uploadData(blob);
    return res;
  }

  async exportAsPdfMultiplePages(querySelectors: string[], documentName?: string) {
    this.loadingOverlayService.show();
    const pages = querySelectors.map(elemId => document.querySelector(elemId) as HTMLElement);
    const docWidth = pages[0].offsetWidth;
    const docHeight = pages[0].offsetHeight;
    const pdf = new jsPDF('l', 'pt', [docWidth, docHeight]);
    for (let i = 0; i < pages.length; i++) {
      await new Promise((resolve) => {
        pages[i].setAttribute('fill', '#fff');
        pages[i].style.setProperty('background', '#fff');
        pages[i].style.setProperty('z-index', '10000');
        const scale = 2; // scaling used to make image sharp, by default it's blurry on high-res screens
        domtoimage.toJpeg(pages[i], {
          style: {
            transform: `scale(${scale}) translate(${pages[i].offsetWidth / 2 / scale}px, ${pages[i].offsetHeight / 2 / scale}px)`
          },
          height: pages[i].offsetHeight * scale,
          width: pages[i].offsetWidth * scale
        }).then((img) => {
          const scaleFactor = pages[i].clientHeight / docHeight;
          pdf.addImage(img, 'JPG', 0, 0, pages[i].clientWidth / scaleFactor, pages[i].clientHeight / scaleFactor);
          if ((i + 1) === pages.length) {
            pdf.save(documentName ? `${documentName}.pdf` : 'Simporter - Report.pdf');
            this.loadingOverlayService.hide();
            console.log('File downlaoded');
          } else {
            pdf.addPage();
          }
          resolve('ok');
        });
      });
    }
  }

}
