import { Location } from '@angular/common';
import { Injectable, TemplateRef, ViewContainerRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {

  private panel: MatSidenav;
  private vcf: ViewContainerRef;

  private sidenavPanelClosedSubject = new Subject();

  public panelClass = '';

  get sidenavPanelClosed() {
    return this.sidenavPanelClosedSubject.asObservable();
  }

  private panelRoute: { activatedRoute: ActivatedRoute, route: string };

  constructor(
    private router: Router,
    private location: Location
  ) { }

  setPanel(sidenav: MatSidenav) {
    this.panel = sidenav;

    this.panel.closedStart.subscribe(res => {
      if (this.panelRoute) {
        const url = this.router.createUrlTree([], { relativeTo: this.panelRoute.activatedRoute }).toString();
        this.location.go(url);
        this.panelRoute = undefined;
      }
      this.sidenavPanelClosedSubject.next(res);
    });
  }

  setContentVcf(viewContainerRef: ViewContainerRef) {
    this.vcf = viewContainerRef;
  }

  private createView(template: TemplateRef<any>) {
    this.vcf.clear();
    this.vcf.createEmbeddedView(template);
  }

  open(template: TemplateRef<any>, panelClass?: string) {
    this.createView(template);
    this.panelClass = panelClass || '';
    this.panel._closedStream.subscribe(panel => this.vcf.clear());
    return this.panel.open();
  }

  openWithRoute(template: TemplateRef<any>, activatedRoute: ActivatedRoute, route: string, panelClass?: string) {
    const url = this.router.createUrlTree([route], { relativeTo: activatedRoute }).toString();
    this.location.go(url);
    this.panelRoute = { activatedRoute, route };
    this.open(template, panelClass);
  }

  close() {
    this.panel.close().then();
  }

  toggle() {
    return this.panel.toggle();
  }
}
