// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  tranlsationsBaseUrl: 'https://simportertestapi-translations-api.azurewebsites.net/api/',
  jtiDashboardBaseUrl: 'https://simporter-jti-api-data.azurewebsites.net/api/',
  jtiBaseUrl: 'https://simporterapi-sl-jti-staging.azurewebsites.net/api/',
  jtiNotificationsUrl: 'https://simporter-jti-api-alerts-staging.azurewebsites.net/',
  socialFeedsUrl: 'https://simportertestapi-socialfeedapi-staging.azurewebsites.net/api/',
  searchMonitoringApi: 'https://simportertestapi-sm-test-api.azurewebsites.net/api/',
  prelaunchApiUrl: 'https://simp-prelaunch-test.azurewebsites.net/api/',
  backendBaseUrl: 'https://simporter-auth-api-staging.azurewebsites.net/api/',
  adminBaseUrl: 'https://simporter-auth-api-staging.azurewebsites.net/',
  needsAnalysisUrl: 'https://simportertestapi-needs-analysis-api-staging.azurewebsites.net/api/needsAnalysis',
  whiteSpaceApiUrl: 'https://simportertestapi-white-space-staging.azurewebsites.net/api/white-space',
  storageAccounts: {
    jtiSocialImageStorageAccount: 'https://socialmediaimages.blob.core.windows.net/jtiimages/',
    reportsStorageAccount: {
      connectionString: 'BlobEndpoint=https://admindashboardstorage.blob.core.windows.net/;QueueEndpoint=https://admindashboardstorage.queue.core.windows.net/;FileEndpoint=https://admindashboardstorage.file.core.windows.net/;TableEndpoint=https://admindashboardstorage.table.core.windows.net/;SharedAccessSignature=sv=2020-02-10&ss=bfqt&srt=sco&sp=rwdlacupx&se=2022-06-28T19:40:35Z&st=2021-04-22T11:40:35Z&spr=https&sig=yo4Syuc07M7ETtBweUEA%2FSpqctuON7rVZL6NouV9Lu4%3D',
      url: 'https://admindashboardstorage.blob.core.windows.net',
      adHocReportsContainer: 'adhocreports',
      regularReportsContainer: 'regularreports',
      draftReportsContainer: 'draftreports'
    }
  }
};
