import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import sha256 from 'sha256';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../core/apiResponseWrapper';
import { LocalStorageService } from '../shared/services/local-storage.service';
import { LoggedInUser } from './models/loggedInUser';
import { LoginResult } from './models/loginResult';
import { OnboardUser } from './models/onboardUser';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  backendBaseUrl: string;

  get currentUserName() {
    try {
      const currentUserFromLocalStorage = this.localStorageService.getItem('current_user', '{}');
      const parsedUser = JSON.parse(currentUserFromLocalStorage) as LoggedInUser;
      return parsedUser.login || '';
    } catch (error) {
      console.log(error);
      return '';
    }
  }

  get currentCompanyName() {
    try {
      const currentUserFromLocalStorage = this.localStorageService.getItem('current_user', '{}');
      const parsedUser = JSON.parse(currentUserFromLocalStorage) as LoggedInUser;
      return parsedUser.companyName || '';
    } catch (error) {
      console.log(error);
      return '';
    }
  }

  constructor(private http: HttpClient, private localStorageService: LocalStorageService, private jwtHelper: JwtHelperService) { }

  submitOnboardUser(request: OnboardUser): Observable<any> {
    return this.http.put<ApiResponse<any>>(environment.adminBaseUrl + 'admin/OnboardUser', request)
      .pipe(map(x => x));
  }

  signIn(email, password): Observable<LoginResult> {
    return this.http.post(environment.backendBaseUrl + 'Login', {
      email,
      password: sha256(password)
    }).pipe(
      map(x => x as LoginResult)
    );
  }

  forgotPassword(email: string) {
    return this.http.post(environment.backendBaseUrl + 'ForgotPassword', { email });
  }

  resetPassword(code, userId, password) {
    return this.http.post(environment.backendBaseUrl + 'ResetPassword', {
      code,
      userId,
      password: sha256(password)
    });
  }

  logout() {
    this.localStorageService.removeItem('access_token');
    this.localStorageService.removeItem('current_user');
    return this.http.post(environment.backendBaseUrl + 'Logout', null);
  }

  hasModuleAccess(moduleName: string): Observable<boolean> {
    const authToken = this.localStorageService.getItem('access_token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${authToken}` });
    return this.http.get(environment.backendBaseUrl + 'CheckModuleAccess', {
      headers,
      params: {
        moduleName
      }
    }).pipe(
      map(x => x as boolean)
    );
  }

  isAuthenticated(): boolean {
    const token = this.localStorageService.getItem('access_token');
    return !this.jwtHelper.isTokenExpired(token);
  }

  getAuthToken() {
    return this.localStorageService.getItem('access_token');
  }

  checkAuthentication(): Observable<any> {
    const authToken = this.localStorageService.getItem('access_token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${authToken}` });
    return this.http.get(environment.backendBaseUrl + 'CheckAuthentication', {
      headers
    }).pipe(
      catchError((err) => {
        this.logout();
        return of(err);
      })
    );
  }
}
