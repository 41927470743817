import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoggedInUser } from 'src/app/auth/models/loggedInUser';
import { NavigationItem, NavigationService } from 'src/app/shared/navigation.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  @Input()
  navigationItems: Observable<NavigationItem[]>;
  isHovered = false;

  helpNavigationItem = {
    label: 'Help',
    description: '',
    icon: 'nav_help',
    route: 'https://simporter.com/how-it-works'
  };

  isJTICompany = false;

  constructor(
    private localStorageService: LocalStorageService,
    private navigationService: NavigationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    const user = (JSON.parse(this.localStorageService.getItem('current_user')) as LoggedInUser);
    this.isJTICompany = user && user.companyName === 'JTI';
  }

  navigateToDefaultRoute() {
    const defaultRoute = this.navigationService.getDefaultRoute();
    this.router.navigate([defaultRoute]);
  }

  openHelpLink() {
    window.open(this.helpNavigationItem.route, '_blank');
  }

}
