import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TableColumn } from 'src/app/core/tableColumn';


@Component({
  selector: 'app-common-table',
  templateUrl: './common-table.component.html',
  styleUrls: ['./common-table.component.scss']
})
export class CommonTableComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() tableData: any[] = [];
  @Input() displayedColumns: string[] = [];
  @Input() columns: TableColumn<any>[] = [];
  @Input() noDataPlaceholder = 'N/A';
  @Input() searchCtrl: FormControl;
  @Input() numberSpaceFormattingEnabled = false;

  @Output() rowClicked = new EventEmitter();

  dataSource = new MatTableDataSource();
  selection = new SelectionModel<any>(true, []);
  pageSize = 20;
  pageSizeOptions: number[] = [5, 10, 20, 50];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tableData && changes.tableData.currentValue.length) {
      this.dataSource = new MatTableDataSource<any>();

      this.dataSource.data = this.tableData;
    }
  }

  ngOnInit(): void {
    if (this.searchCtrl) {
      this.searchCtrl.valueChanges.subscribe(value => this.onFilterChange(value));
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  onRowClicked(row: any) {
    this.rowClicked.emit(row);
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

}
