import { ApexOptions } from 'ng-apexcharts';
import { mergeDeep } from 'src/app/shared/helpers/mergeDeep';

export const defaultChartOptions = (options: Partial<ApexOptions> = {}): ApexOptions => mergeDeep({
    grid: {
        show: false,
        padding: {
            left: 0,
            right: 0
        }
    },
    chart: {
        parentHeightOffset: 0,
        type: 'area',
        toolbar: {
            show: false,
        },
        sparkline: {
            enabled: true
        }
    },
    labels: [],
    dataLabels: {
        enabled: false
    },
    stroke: {
        curve: 'smooth',
        width: 3
    },
    fill: {
        gradient: {}
    },
    xaxis: {
        type: 'numeric',
        labels: {
            show: false,
            style: {
                cssClass: 'text-secondary fill-current caption font-medium',
                fontFamily: 'inherit'
            }
        },
        axisBorder: {
            show: true,
            color: '#EEEEEE'
        },
        axisTicks: {
            show: false
        },
        floating: false,
        tooltip: {
            enabled: false,
        },
    },
    yaxis: {
        labels: {
            show: false,
            style: {
                cssClass: 'text-secondary fill-current caption font-medium',
                fontFamily: 'inherit'
            }
        },
        axisBorder: {
            show: false
        },
        axisTicks: {
            show: false
        },
    },
    legend: {
        position: 'top',
        fontFamily: 'inherit',
        horizontalAlign: 'left',
        offsetX: -18,
        itemMargin: {
            horizontal: 0,
            vertical: 12
        },
        markers: {
            radius: 4,
            width: 12,
            height: 12
        },
        labels: {
            colors: ['var(--text-secondary-color)']
        }
    },
    tooltip: {
        enabled: false,
        x: { show: false },
        y: { show: false }
    },
    states: {
        hover: {
            filter: {
                type: 'none',
            }
        },
    }
}, options);
