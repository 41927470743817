import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { NotificationCountData, NotificationsData, NotificationsService } from '../../notifications.service';
import { SidenavService } from '../../sidenav.service';

@Component({
  selector: 'app-notifications-popup',
  templateUrl: './notifications-popup.component.html',
  styleUrls: ['./notifications-popup.component.scss']
})
export class NotificationsPopupComponent implements OnInit {

  countValue: Observable<NotificationCountData>;
  notificationsList: NotificationsData[];

  @Output() isShow: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private notificationsService: NotificationsService,
    public sidenavService: SidenavService
  ) { }

  ngOnInit(): void {
    this.notificationsService.getNotifications(1, 5)
      .subscribe(notifications => {
        this.notificationsList = notifications;
      });
  }

  onSubmitAllAsRead() {
    this.notificationsService.updateMarkAllAsRead().subscribe(e => {
      this.notificationsList.map(x => x.isRead);
    });
  }

  closePopup() {
    this.isShow.emit();
  }
}

