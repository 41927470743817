import { Directive, Input, TemplateRef, ViewContainerRef, OnInit } from '@angular/core';

@Directive({
  selector: '[appDelay]'
})
export class DelayDirective implements OnInit {

  @Input()
  appDelay: number;

  constructor(
    private templateRef: TemplateRef<any>,
    private vcr: ViewContainerRef
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.vcr.createEmbeddedView(this.templateRef);
    }, this.appDelay);
  }
}
