import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Observable, Subscription } from 'rxjs';
import { NavigationItem, NavigationService } from 'src/app/shared/navigation.service';
import { SidenavService } from '../../sidenav.service';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss']
})
export class ContentLayoutComponent implements OnInit, OnDestroy {

  navigationItems: Observable<NavigationItem[]>;
  navigationItemsSubjectSubscription: Subscription;

  @ViewChild('panel', { static: true }) private sidePanel: MatSidenav;
  @ViewChild('content', { static: true, read: ViewContainerRef }) private vcf: ViewContainerRef;

  constructor(
    private navigationService: NavigationService,
    public sidenavService: SidenavService
  ) {
    this.navigationItems = this.navigationService.getSidebarNavigationItems();
    this.navigationItemsSubjectSubscription = this.navigationService.navigationItemsSubject.subscribe(res => {
      this.navigationItems = this.navigationService.getSidebarNavigationItems();
    });
  }

  ngOnInit(): void {
    this.sidenavService.setPanel(this.sidePanel);
    this.sidenavService.setContentVcf(this.vcf);
  }

  ngOnDestroy() {
    if (this.navigationItemsSubjectSubscription) {
      this.navigationItemsSubjectSubscription.unsubscribe();
    }
  }

}
