import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
// import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { interval, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { JTI_COUNTRIES } from 'src/app/country-selection/data/jtiCountries';
import { EditorService } from 'src/app/editor/editor.service';
import { CountriesService } from 'src/app/shared/services/countries.service';
import { NotificationsService } from '../../notifications.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  logoutSubscription: Subscription;
  countries = JTI_COUNTRIES;

  countryCtrl = new FormControl();
  languageCtrl = new FormControl(this.translate.currentLang);
  isShow = false;
  username = this.authService.currentUserName;
  companyName = this.authService.currentCompanyName;
  count = 0;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private countriesService: CountriesService,
    public editorService: EditorService,
    public dialog: MatDialog,
    private notificationsService: NotificationsService,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    try {
      this.countriesService.currentCountry.subscribe(res => {
        this.countryCtrl.setValue(res);
      });
    } catch (error) {
      console.log(error);
    }


    this.initializeNotificationCount();
  }

  initializeNotificationCount() {
    // we load notifications only for JTI company
    if (this.authService.currentCompanyName === 'JTI') {
      this.getUnreadNotificationsCount();
    }

    // each time notification is marked as read in outside component
    // it emits new event in subject
    this.notificationsService.unreadCountChangedSubject.subscribe(() => {
      this.getUnreadNotificationsCount();
    });
  }

  getUnreadNotificationsCount() {
    interval(10000).pipe(
      switchMap(x => this.notificationsService.getNotificationCount())
    ).subscribe(count => {
      this.count = count;
    });
  }

  navigateToCountryDashboard(countryCode: string) {
    this.countriesService.setCountryAndNavigateToDashboard(countryCode.toLowerCase());
  }

  logout() {
    this.authService.logout().subscribe(() => {
      this.router.navigate(['login']);
    });
  }

  ngOnDestroy() {
    if (this.logoutSubscription) {
      this.logoutSubscription.unsubscribe();
    }
  }
  getImgUrl(item): string {
    return 'assets/img/country-flags/' + item.toLowerCase() + '.png';
  }

  toggleNotificationPopup() {
    this.isShow = !this.isShow;
  }
}
