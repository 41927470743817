import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { Country } from 'src/app/core/country';
import { LocalStorageService } from './local-storage.service';
import { Router } from '@angular/router';
import { NavigationService } from '../navigation.service';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  currentCountry = new BehaviorSubject<string>(this.localStorage.getItem('country', 'gl'));

  get currentCountryInstant() {
    return this.localStorage.getItem('country', 'gl');
  }

  constructor(
    private router: Router,
    private localStorage: LocalStorageService,
    private navigationService: NavigationService
  ) { }

  getCountriesList(): Observable<Country[]> {
    return of([{
      flagUrl: 'assets/img/country-flags/usa.png',
      name: 'United States',
      code: 'us'
    }] as Country[]);
  }

  setCountryAndNavigateToDashboard(countryCode: string) {
    this.localStorage.setItem('country', countryCode);
    this.currentCountry.next(countryCode);
    this.navigationService.navigationItemsSubject.next('reload');
    this.navigationService.getSidebarNavigationItems().subscribe(res => {
      this.router.navigate([res[0].route]);
    });
  }

  setCountryAndNavigateToPage(countryCode: string, pageName: string) {
    this.localStorage.setItem('country', countryCode);
    this.currentCountry.next(countryCode);
    this.navigationService.navigationItemsSubject.next('reload');
    this.navigationService.getSidebarNavigationItems().subscribe(res => {
      this.router.navigate([res.find(x => x.label === pageName)?.route || res[0].route]);
    });
  }
}
