import { Component, NgZone, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './auth/auth.service';
import { GoogleAnalyticsService } from './shared/services/google-analytics.service';
import { LocalStorageService } from './shared/services/local-storage.service';
import { SplashScreenService } from './shared/services/splash-screen.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'Simporter - AI Product Launch Toolbox';

  iconsList = [
    'lightbulb',
    'stock',
    'rocket',
    'bar',
    'file',
    'comment',
    'download',
    'download-cloud',
    'profile',
    'notification',
    'paper',
    'star',
    'star_active',
    'comment_positive',
    'comment_negative',
    'like_comment',
    'help_outline',
    'social_share',
    'social_comment',
    'social_like',
    'star_score',
    'thumbs_up',
    'smile',
    'like',
    'cart',
    'compare',
    'compass',
    'copy',
    'edit',
    'delete',
    'ipad',
    'whale',
    'add_user',
    'blue_star_inactive',
    'blue_star_active',
    'arrowDown',
    'arrowToTop',
    'close',
    'expand',
    'magicCircle',
    'download-need',
    'download-jti',
    'arrowOptions',
    'smile_positive',
    'smile_negative',
    'expand_arrow',
    'fire',
    'search_icon',
    'plus',
    'message',
    'successfullyReset',
    'tools',
    'office_worker',
    'male_office_worker',
    'programmer',
    'download_report',
    'preview',
    'book',
    'blueLogo',
    'edit_review',
    'remove_tag',
    'drag_handle',
    'addPlus',
    'checkIcon',
    'visibility',
    'visibility_off',
    'info',
    'star_favourite',
    'star_favourite_active',
    'chevron_up',
    'chevron_down',
    'notification_active',
    'star_notification'
  ];

  sidebarIconsList = [
    'nav_activity',
    'nav_chart',
    'nav_edit_square',
    'nav_game',
    'nav_search',
    'nav_send',
    'nav_star',
    'nav_help',
    'nav_comment',
    'nav_lightbulb',
    'nav_file',
    'nav_stock',
    'nav_like_comment',
    'nav_dashboard'
  ];

  headerIconsList = [
    'document',
    'notification',
    'profile'
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private authService: AuthService,
    private translate: TranslateService,
    public splashScreenService: SplashScreenService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private router: Router,
    private ngZone: NgZone
  ) {
    // Method Cypress will call
    if ((window as any).Cypress) {
      (window as any).cypressNavigateByUrl = (url: string) => this.cypressNavigateByUrl(url);
    }
  }

  public cypressNavigateByUrl(url: string) {
    this.ngZone.run(() => {
      this.router.navigateByUrl(url);
    });
  }

  ngOnInit(): void {
    this.initializeCustomIcons(this.headerIconsList, 'assets/img/icons/header', 'svg');
    this.initializeCustomIcons(this.sidebarIconsList, 'assets/img/icons/sidebar', 'svg');
    this.initializeCustomIcons(this.iconsList, 'assets/img', 'svg');

    // this is ping request to check if ticket is valid
    this.authService.checkAuthentication().subscribe(res => {
    });

    this.translate.setDefaultLang('en');
    this.translate.use('en');

    this.googleAnalyticsService.setUserId();
  }

  initializeCustomIcons(iconsSet: string[], prefix: string, extension) {
    iconsSet.forEach(icon => {
      this.matIconRegistry.addSvgIcon(
        icon,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`${prefix}/${icon}.${extension}`)
      );
    });
  }
}
