import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { JwtModule, JwtModuleOptions } from '@auth0/angular-jwt';
import { MatCarouselModule } from '@ngbmodule/material-carousel';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AppComponent } from './app.component';
import { routes } from './app.module.routes';
import { CustomReuseStrategy } from './customRouteReuseStrategy';
import { LayoutModule } from './layout/layout.module';
import { AuthGuard } from './shared/guards/auth-guard.guard';
import { ModuleAccessGuard } from './shared/guards/module-access.guard';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { GoogleAnalyticsService } from './shared/services/google-analytics.service';
import { SharedModule } from './shared/shared.module';

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const JwtTokenModuleOptions: JwtModuleOptions = {
  config: {
    tokenGetter: jwtTokenGetter
  }
};

export function jwtTokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    LayoutModule,
    SharedModule,
    MatIconModule,
    MatCarouselModule,
    HttpClientModule,
    ClipboardModule,
    BrowserAnimationsModule,
    LoadingBarHttpClientModule,
    NgxSkeletonLoaderModule.forRoot(),
    JwtModule.forRoot(JwtTokenModuleOptions),
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      defaultLanguage: 'en'
    }),
  ],
  providers: [
    AuthGuard,
    ModuleAccessGuard,
    GoogleAnalyticsService,
    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
