import { AfterViewInit, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ApexOptions } from 'apexcharts';
import { ChartComponent } from 'ng-apexcharts';
import { ApexAxisSerie } from 'src/app/core/apexAxisSerie';
import { NumberSpacePipe } from '../../pipes/number-space.pipe';
import { util } from '../../util';

@Component({
  selector: 'app-common-prediction-chart',
  templateUrl: './common-prediction-chart.component.html',
  styleUrls: ['./common-prediction-chart.component.scss']
})
export class CommonPredictionChartComponent implements OnChanges, AfterViewInit {
  @Input() chartData: ApexAxisSerie[];
  @Input() chartHeightPx = '400px';

  @Input() colors = ['#1867EF', '#1867EF', '#E1AD60', '#E1AD60', '#00A6B0', '#00A6B0'];

  @ViewChild('chart', { static: false }) chart: ChartComponent;
  public chartOptions: Partial<ApexOptions>;

  numberSpacePipe = new NumberSpacePipe();

  constructor() {
    this.chartOptions = {
      series: [],
      chart: {
        height: this.chartHeightPx,
        width: '100%',
        type: 'line',
        animations: {
          enabled: false
        },
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 4
      },
      yaxis: {
        axisBorder: {
          show: false,
          color: '#78909C',
          offsetX: 0,
          offsetY: 0
        },
        axisTicks: {
          show: false,
          color: '#78909C',
          width: 6,
          offsetX: 0,
          offsetY: 0
        },
        labels: {
          formatter: (value) => this.numberSpacePipe.transform(value)
        }
      },
      xaxis: {
        type: 'datetime',
        labels: {
          style: {
            fontFamily: 'Poppins',
            fontSize: '12px',
            colors: '#6A7080'
          }
        }
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy'
        },
        z: {
          formatter: () => '',
          title: ''
        },
        shared: true
      },
      legend: {
        show: false,
        horizontalAlign: 'left',
        position: 'left',
        fontFamily: 'Poppins',
        fontSize: '12px',
        offsetY: 16,
        height: 32,
        markers: {
          radius: 50
        },
        labels: {
          colors: '#6A7080'
        }
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          }
        },
        yaxis: {
          lines: {
            show: true,
          },
        }
      },
      annotations: {},
      colors: this.colors,
      fill: {
        type: ['gradient', 'solid', 'gradient', 'solid', 'gradient', 'solid'],
        gradient: {
          type: 'vertical',
          shadeIntensity: 0,
          opacityFrom: 0.5,
          opacityTo: 0.2,
          stops: [0, 90, 100]
        }
      }
    };
  }

  ngAfterViewInit() {
    this.chart.chart.height = this.chartHeightPx || this.chart.chart.height;
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.chartData.currentValue && changes.chartData.currentValue !== this.chartOptions.series) {
      this.initChartData();
    }
  }

  initChartData() {
    const self = this;
    if (self.chart) {
      if (this.chartData.length) {
        const divisionPoint = this.findMaxFirstDatasetPointDate(this.chartData);
        const chartData = [];

        // splitting each dataset into 2, based on division point
        // assuming first dataset is fact data and data after division point - predicted
        self.chartData.forEach((x, i) => {
          if (!x.strokeColor) {
            x.strokeColor = self.colors[i % this.colors.length];
          }
          chartData.push(
            // This is for fact data timeline
            {
              name: x.name + 'Fact',
              data: [], // (x.data as any[]).filter(p => p.x <= divisionPoint),
              type: 'area',
              strokeColor: util.hexToRGB(x.strokeColor, 0.5),
            },
            {
              name: x.name,
              data: (x.data as any[]).filter(p => p.x >= divisionPoint),
              type: 'line',
              strokeColor: x.strokeColor
            });
        });
        self.chartOptions.colors = chartData.map(x => x.strokeColor);
        self.chartOptions.series = chartData;
        // temporarily removed setting annotations to show division point as marker on chart
        // this.setAnnotations(self.chartOptions, self.chartOptions.series as any, divisionPoint);
      } else {
        self.chartOptions.series = [];
      }
      setTimeout(() => {
        self.chart.updateOptions(self.chartOptions, false, true, true);
      }, 1);
    } else {
      setTimeout(() => {
        self.initChartData();
      }, 100);
    }
  }

  private findMaxFirstDatasetPointDate(series: ApexAxisSerie[]) {
    return Math.max(...series.filter(x => x.data.length).map(x => x.data[0].x.getTime()));
  }

  // two following methods can be used to show division point from initial logic for prediction charts
  private findClosestDateToGoal(series: ApexAxisSerie[], goal: number) {
    return (series[0] as any as ApexAxisSerie).data.reduce((prev, curr) => {
      return (Math.abs(curr.x.getTime() - goal) < Math.abs(prev.x.getTime() - goal) ? curr : prev);
    }).x;
  }

  private setAnnotations(options: ApexOptions, series: ApexAxisSerie[], divisionPoint: Date) {
    options.annotations = {
      xaxis: [{
        x: divisionPoint.getTime(),
        borderColor: '#1867EF',
        strokeDashArray: 10
      }],
      points: series.filter((x, i) => i % 2 === 1 && x.data[0]).map((ds, dsIndex) => {
        return {
          x: divisionPoint.getTime(),
          y: ds.data[0].y,
          marker: {
            size: 6,
            fillColor: '#fff',
            strokeColor: ds.strokeColor,
            strokeWidth: 3,
            shape: 'circle',
            radius: 3
          }
        };
      })
    };
  }

  toggleSerie(serieName: string) {
    this.chart.toggleSeries(serieName);
    this.chart.toggleSeries(serieName + ' Prediction');
  }
}
