import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appTextSelection]'
})
export class TextSelectionDirective {

  @Output()
  textSelectionChanged = new EventEmitter<Selection>();

  constructor() { }

  @HostListener('click') onMouseEnter() {
    const selection = window.getSelection();
    if (selection.toString().length !== 0) {
      this.textSelectionChanged.emit(selection);
    }
  }

}
