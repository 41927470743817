import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { NgApexchartsModule } from 'ng-apexcharts';
import { DownloadComponent } from '../shared/components/download/download.component';
import { AreaChartComponent } from './components/chart/chart.component';
import { CommonChartLegendComponent } from './components/common-chart-legend/common-chart-legend.component';
import { CommonHorizontalBarChartComponent } from './components/common-horizontal-bar-chart/common-horizontal-bar-chart.component';
import { CommonPieChartComponent } from './components/common-pie-chart/common-pie-chart.component';
import { CommonPlotChartComponent } from './components/common-plot-chart/common-plot-chart.component';
import { CommonPredictionChartComponent } from './components/common-prediction-chart/common-prediction-chart.component';
import { CommonProjectsTableComponent } from './components/common-projects-table/common-projects-table.component';
import { CommonSliderComponent } from './components/common-slider/common-slider.component';
import { CommonTableComponent } from './components/common-table/common-table.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { LoadingOverlayComponent } from './components/loading-overlay/loading-overlay.component';
import { WidgetQuickLineChartComponent } from './components/widget-quick-line-chart/widget-quick-line-chart.component';
import { WidgetQuickLineValueComponent } from './components/widget-quick-line-value/widget-quick-line-value.component';
import { DelayDirective } from './directives/delay.directive';
import { ImgFallbackDirective } from './directives/img-fallback.directive';
import { PreventDoubleClickDirective } from './directives/prevent-double-click.directive';
import { TextSelectionDirective } from './directives/text-selection.directive';
import { FormatNumberPipe } from './pipes/format-number.pipe';
import { NumberSpacePipe } from './pipes/number-space.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { CustomSlideToggleComponent } from './components/custom-slide-toggle/custom-slide-toggle.component';
import { CopyClipboardDirective } from './directives/copy-clipboard.directive';

@NgModule({
  declarations: [
    CommonTableComponent,
    CommonPlotChartComponent,
    LoadingOverlayComponent,
    CommonPieChartComponent,
    CommonHorizontalBarChartComponent,
    DelayDirective,
    PreventDoubleClickDirective,
    WidgetQuickLineValueComponent,
    WidgetQuickLineChartComponent,
    SafePipe,
    FormatNumberPipe,
    NumberSpacePipe,
    CommonProjectsTableComponent,
    CommonPredictionChartComponent,
    CommonChartLegendComponent,
    CommonSliderComponent,
    DownloadComponent,
    ConfirmationDialogComponent,
    AreaChartComponent,
    ImgFallbackDirective,
    TextSelectionDirective,
    ClickOutsideDirective,
    CustomSlideToggleComponent,
    CopyClipboardDirective
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatCardModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatButtonModule,
    FlexLayoutModule,
    MatTooltipModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    NgApexchartsModule,
    MatProgressSpinnerModule,
    TranslateModule,
    MatCheckboxModule,
    MatInputModule,
    ReactiveFormsModule,
    NgxSliderModule,
    FormsModule
  ],
  exports: [
    MatIconModule,
    FlexLayoutModule,
    CommonTableComponent,
    CommonPlotChartComponent,
    CommonPieChartComponent,
    CommonHorizontalBarChartComponent,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    TranslateModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatTooltipModule,
    NgApexchartsModule,
    DelayDirective,
    PreventDoubleClickDirective,
    WidgetQuickLineValueComponent,
    WidgetQuickLineChartComponent,
    SafePipe,
    FormatNumberPipe,
    NumberSpacePipe,
    MatDialogModule,
    CommonProjectsTableComponent,
    CommonPredictionChartComponent,
    CommonChartLegendComponent,
    CommonSliderComponent,
    DownloadComponent,
    ConfirmationDialogComponent,
    ImgFallbackDirective,
    TextSelectionDirective,
    ClickOutsideDirective,
    CustomSlideToggleComponent
  ],
  entryComponents: [
    ConfirmationDialogComponent
  ]
})
export class SharedModule { }
