import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { ApiResponse } from '../core/apiResponseWrapper';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  unreadCountChangedSubject = new Subject();

  private jtiNotificationsUrl = environment.jtiNotificationsUrl + 'api/notifications/';

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {
  }

  getNotificationCount(): Observable<number> {
    // we create observable from fetch API to avoid using http client for this request
    // the reason is each http client request triggers loader bar on top of the page
    // and it will be displayed on each recurring getNotificationsCount() request which is triggered by interval
    // TODO: think of utilizing Sockets for this functionality
    return new Observable(observer => {
      fetch(this.jtiNotificationsUrl + `unread-count?username=${this.authService.currentUserName}&company=${this.authService.currentCompanyName}`)
        .then(response => response.json())
        .then(data => {
          observer.next(data);
          observer.complete();
        })
        .catch(err => observer.error(err));
    }).pipe(
      map((x: any) => x?.result?.count)
    );
  }

  getNotifications(page: number, limit: number): Observable<NotificationsData[]> {
    return this.http.get<ApiResponse<NotificationsData[]>>(this.jtiNotificationsUrl + 'list', {
      params: {
        page: page.toString(),
        limit: limit.toString(),
        username: this.authService.currentUserName,
        company: this.authService.currentCompanyName
      }
    }).pipe(
      map(x => {
        return x.result;
      })
    );
  }

  updateMarkAsRead(notificationid: number) {
    return this.http.post<ApiResponse<any>>(this.jtiNotificationsUrl + 'mark-as-read'
      + `?notificationid=${notificationid}&username=${encodeURIComponent(this.authService.currentUserName)}&company=${encodeURIComponent(this.authService.currentCompanyName)}`
      , null
      , {
      })
      .pipe(catchError(this.handleError))
      .pipe(map(x => x)
      );
  }


  updateMarkAllAsRead() {
    return this.http.post<ApiResponse<any>>(this.jtiNotificationsUrl + 'mark-all-as-read', {}, {
      params: {
        username: this.authService.currentUserName,
        company: this.authService.currentCompanyName
      }
    })
      .pipe(catchError(this.handleError));
  }
  handleError(error: HttpErrorResponse) {
    return throwError(error.error.message);
  }
}

export interface NotificationsData {
  id: number;
  text: string;
  country: string;
  page: string;
  date: string;
  isRead: boolean;
}

export interface NotificationsParameters {
  username: string;
  company: string;
  page: number;
  limit: number;
}

export interface NotificationCountData {
  count: number;
}

export interface NotificationCountParameter {
  username: string;
  company: string;
}
