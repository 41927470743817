import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-custom-slide-toggle',
  templateUrl: './custom-slide-toggle.component.html',
  styleUrls: ['./custom-slide-toggle.component.scss']
})
export class CustomSlideToggleComponent implements OnInit {

  @Input()
  checked: boolean;

  @Output()
  valueChanged = new EventEmitter<MatSlideToggleChange>();

  constructor() { }

  ngOnInit(): void {
  }

}
