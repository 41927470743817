
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNumber'
})
export class FormatNumberPipe implements PipeTransform {

  transform(value: string): string {
    if (value) {
      return value.replace(',', '.');
    }
    return '';
  }

}
