import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private storageSub = new BehaviorSubject<any>('changed');

  watchStorage(): Observable<any> {
    return this.storageSub.asObservable();
  }

  getItem(key: string, fallback?: string): string {
    const value = localStorage.getItem(key);
    return value || fallback;
  }

  setItem(key: string, data: any) {
    localStorage.setItem(key, data);
    this.storageSub.next('changed');
  }

  removeItem(key) {
    localStorage.removeItem(key);
    this.storageSub.next('changed');
  }
}
