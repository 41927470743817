import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';

@Injectable()
export class ModuleAccessGuard implements CanActivate {
  constructor(private authService: AuthService, private snackbar: MatSnackBar) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.hasModuleAccess(next.data.moduleName).pipe(
      tap(x => {
        if (!x) {
          this.snackbar.open('No access to route', '', { duration: 3000 });
        }
      }),
      catchError((err) => {
        console.log(err);
        this.snackbar.open('No access to route', '', { duration: 3000 });
        return of(false);
      })
    );
  }
}
