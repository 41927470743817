import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, SimpleChanges, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { ChartComponent, ApexOptions } from 'ng-apexcharts';

@Component({
  selector: 'app-common-pie-chart',
  templateUrl: './common-pie-chart.component.html',
  styleUrls: ['./common-pie-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonPieChartComponent implements OnChanges, OnInit {

  @Input()
  labels: string;
  @Input()
  series: number[];
  @Input()
  legendPlacement: string;
  @Input()
  legendEnabled = true;

  @Input() colors: string[] = ['#aeaeae', '#00B0F0', '#CC0066', '#197AAF'];

  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ApexOptions>;

  constructor(private cd: ChangeDetectorRef) {
    this.chartOptions = {
      series: [],
      plotOptions: {
        pie: {
          donut: {
            size: '65%'
          }
        }
      },
      chart: {
        width: '100%',
        height: '400px',
        type: 'donut',
        animations: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      labels: [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ],
      legend: {
        show: true,
        horizontalAlign: 'left',
        position: 'top',
        fontFamily: 'Poppins',
        fontSize: '14px',
        offsetY: 16,
        markers: {
          radius: 50
        },
        labels: {
          colors: '#BFBFBF'
        }
      },
      grid: {
        padding: {
          top: 64
        }
      },
      yaxis: {
        show: false,
        labels: {
          show: false
        },
        tickAmount: 0
      },
      tooltip: {
        y: {
          formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
            return value + '%';
          }
        }
      },
      xaxis: {
        categories: []
      },
      colors: ['#00B0F0', '#CC0066', '#A64D79', '#197AAF', '#D5A6BD', '#EE8437']
    };
  }


  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.labels && changes.labels.currentValue)
      || (changes.series && changes.series.currentValue)
      || (changes.colors && changes.colors.currentValue)
    ) {
      if (this.chart) {
        this.initChartData();
      } else {
        setTimeout(() => {
          this.initChartData();
        }, 1000);
      }
    }
  }

  ngOnInit() {
    this.chartOptions.colors = this.colors;
    if (this.chart) {
      this.chart.updateOptions(this.chartOptions, false, true, true);
    }
  }

  initChartData() {
    this.chartOptions = Object.assign(this.chartOptions, { labels: this.labels, colors: this.colors });
    if (!this.legendEnabled) {
      this.chartOptions.legend.show = false;
    }
    this.chart.updateOptions(this.chartOptions, true, true, true);
    this.chartOptions.series = this.series;
    this.chart.updateOptions(this.chartOptions.series, false, true, true);
    if (this.legendPlacement === 'left') {
      this.chart.legend = {
        show: true,
        horizontalAlign: 'left',
        position: 'left',
        fontFamily: 'Poppins',
        fontSize: '14px',
        offsetY: 16,
        markers: {
          radius: 50
        },
        labels: {
          colors: '#BFBFBF'
        },
        itemMargin: {
          horizontal: 5,
          vertical: 10
        },
      };
      this.chart.updateOptions(this.chartOptions.legend, false, true, true);
    }
    this.cd.detectChanges();
  }

}
