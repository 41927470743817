import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/shared/navigation.service';
import { CountriesService } from 'src/app/shared/services/countries.service';
import { NotificationsData, NotificationsService } from '../../notifications.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationComponent implements OnInit {

  @Input() notification: NotificationsData;

  @Output() linkClicked = new EventEmitter();

  get notificationIcon() {
    return this.navigationService.jtiSidebarItems.find(x => x.label === this.notification.page)?.icon || 'star';
  }

  constructor(
    private notificationsService: NotificationsService,
    private navigationService: NavigationService,
    private countriesService: CountriesService,
    private router: Router
  ) { }

  ngOnInit() {
    if (this.notification && !this.notification?.isRead) {
      this.setTimeoutMarkAsRead(this.notification.id, 3000);
    }
  }

  setTimeoutMarkAsRead(notificationid, timeout) {
    const tm = window.setTimeout(() => {
      this.notificationsService.updateMarkAsRead(notificationid).subscribe(() => {
        this.notification.isRead = true;
        clearTimeout(tm);
      });
    }, timeout);
  }

  navigateToNotificationLink() {
    this.linkClicked.emit();
    this.countriesService.setCountryAndNavigateToPage(this.notification.country.toLowerCase(), this.notification.page);
  }
}
