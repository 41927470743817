import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccessService {

  constructor(private http: HttpClient) { }

  getAvailableModules(): Observable<ModuleAccess[]> {
    return this.http.get<ModuleAccess[]>(environment.backendBaseUrl + 'GetAvailableModules');
  }
}

export interface ModuleAccess {
  companyId: string;
  companyName: string;
  moduleId: string;
  moduleName: string;
}
