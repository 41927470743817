import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NavigationItem, NavigationService } from 'src/app/shared/navigation.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {

  navigationItems: Observable<NavigationItem[]>;

  constructor(private navigationService: NavigationService) {
  }

  ngOnInit(): void {
  }

}
