import { CountrySelectionModel } from '../countries-by-continent';


// Matrix of countries for displaying on country selection page
export const JTI_COUNTRIES_GRID: CountrySelectionModel[][] = [
  [{
    disabled: false, numberOfSources: 0, visible: true,
    continentCode: 'GL',
    continentName: 'Favorites',
    countryName: 'Global',
    isFavorite: true,
    countryNumber: 4,
    threeLetterCountryCode: 'GLB',
    twoLetterCountryCode: 'GL'
  }],
  [{
    disabled: false, numberOfSources: 11, visible: true,
    isFavorite: true,
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Russia',
    countryNumber: 643,
    threeLetterCountryCode: 'RUS',
    twoLetterCountryCode: 'RU'
  }, {
    disabled: false, numberOfSources: 0, visible: true,
    isFavorite: true,
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'United Kingdom',
    countryNumber: 826,
    threeLetterCountryCode: 'GBR',
    twoLetterCountryCode: 'GB'
  }, {
    disabled: false, numberOfSources: 0, visible: true,
    isFavorite: true,
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Italy',
    countryNumber: 380,
    threeLetterCountryCode: 'ITA',
    twoLetterCountryCode: 'IT'
  }],
  [{
    disabled: false, numberOfSources: 0, visible: true,
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Germany',
    countryNumber: 276,
    threeLetterCountryCode: 'DEU',
    twoLetterCountryCode: 'DE',
    isFavorite: true
  }, {
    disabled: false, numberOfSources: 0, visible: true,
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Spain',
    countryNumber: 724,
    threeLetterCountryCode: 'ESP',
    twoLetterCountryCode: 'ES',
    isFavorite: true
  }, {
    disabled: false, numberOfSources: 0, visible: true,
    isFavorite: true,
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Poland',
    countryNumber: 616,
    threeLetterCountryCode: 'POL',
    twoLetterCountryCode: 'PL'
  }], [{
    disabled: false, numberOfSources: 0, visible: true,
    isFavorite: true,
    continentCode: 'A',
    continentName: 'Americas',
    countryName: 'United States',
    countryNumber: 840,
    threeLetterCountryCode: 'USA',
    twoLetterCountryCode: 'US'
  }, {
    disabled: false, numberOfSources: 0, visible: true,
    isFavorite: true,
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'South Korea',
    countryNumber: 410,
    threeLetterCountryCode: 'KOR',
    twoLetterCountryCode: 'KR'
  },
  {
    disabled: false, numberOfSources: 0, visible: true,
    isFavorite: true,
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Japan',
    countryNumber: 392,
    threeLetterCountryCode: 'JPN',
    twoLetterCountryCode: 'JP'
  }]
];

// concatenated list (1-dimensional array) of countries from the matrix above
export const JTI_COUNTRIES: CountrySelectionModel[] = [].concat(...JTI_COUNTRIES_GRID);
