import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { SharedModule } from '../shared/shared.module';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { FullLayoutComponent } from './components/full-layout/full-layout.component';
import { ContentLayoutComponent } from './components/content-layout/content-layout.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AdminLayoutComponent } from './components/admin-layout/admin-layout.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatRippleModule } from '@angular/material/core';
import { ClickOutsideModule } from 'ng-click-outside';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NotificationCentreComponent } from './components/notification-centre/notification-centre.component';
import { NotificationComponent } from './components/notification/notification.component';
import { NotificationsPopupComponent } from './components/notifications-popup/notifications-popup.component';

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
    AdminLayoutComponent,
    NotificationsPopupComponent,
    NotificationComponent,
    NotificationCentreComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    MatMenuModule,
    FlexLayoutModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatBadgeModule,
    MatSidenavModule,
    MatRippleModule,
    ClickOutsideModule,
    InfiniteScrollModule,
  ],
  exports: [FooterComponent, HeaderComponent, SidebarComponent]
})
export class LayoutModule { }
