import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ApexAxisSerie } from 'src/app/core/apexAxisSerie';
import { ILegendItem } from '../../interfaces';

@Component({
  selector: 'app-common-chart-legend',
  templateUrl: './common-chart-legend.component.html',
  styleUrls: ['./common-chart-legend.component.scss']
})
export class CommonChartLegendComponent implements OnInit, OnChanges {

  @Input() predictionInSameSerie = false;
  @Input() showHorizontal = false;
  @Input() showToggles = true;
  @Input() chartData: ApexAxisSerie[];

  @Input() toggleControlType: 'checkbox' | 'slider' = 'slider';
  @Output() legendItemToggled = new EventEmitter<string>();

  defaultColors = ['#1867EF', '#1867EF', '#E1AD60', '#E1AD60', '#00A6B0', '#00A6B0'];
  legendItems: ILegendItem[] = [];

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.SetChartData();
  }

  SetChartData() {
    if (!Array.isArray(this.chartData as ApexAxisSerie[])) {
      console.warn('Chart data is not correct. It should be "ApexAxisSerie[]"');
      return;
    }
    this.legendItems = this.chartData?.map((ds, i) => {
      return {
        name: ds.name,
        color: ds.strokeColor || this.defaultColors[i],
        visible: true
      };
    }).filter((x, i) => this.predictionInSameSerie || i % 2 === 0);
  }

  onSerieToggled(legendItem: ILegendItem) {
    legendItem.visible = !legendItem.visible;
    this.legendItemToggled.emit(legendItem.name);
  }
}
