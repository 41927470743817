import { Injectable } from '@angular/core';
import { LoggedInUser } from 'src/app/auth/models/loggedInUser';
import { LocalStorageService } from './local-storage.service';

// tslint:disable-next-line:ban-types
declare let ga: Function; // this is global google analytics function

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor(private localStorageService: LocalStorageService) { }

  public emitGoogleAnalyticsEvent(
    eventName: string,
    eventCategory: string,
    eventLabel: string = null,
    eventValue: number = null) {
    ga('send', {
      hitType: 'event',
      eventCategory,
      eventName,
      eventLabel
    });
  }

  public emitLoginEvent() {
    try {
      const currentUser = (JSON.parse(this.localStorageService.getItem('current_user')) as LoggedInUser);
      this.emitGoogleAnalyticsEvent('login', 'auth', currentUser.login, 1);
    } catch (error) {
      console.log(error);
    }
  }

  public setUserId() {
    try {
      const currentUser = (JSON.parse(this.localStorageService.getItem('current_user')) as LoggedInUser);
      if (currentUser.id) {
        ga('create', 'UA-176312812-1', { userId: currentUser.id });
        ga('set', 'dimension1', currentUser.id);
      } else {
        ga('create', 'UA-176312812-1', { userId: 'auto' });
        ga('set', 'dimension1', 'auto');
      }
      ga('send', 'pageview');
    } catch (error) {
      console.log(error);
    }
  }
}
