import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ApexAxisChartSeries, ApexNonAxisChartSeries, ApexOptions } from 'ng-apexcharts';
import { scaleInOutAnimation } from 'src/app/animations/scale-in-out.animation';
import { hex2rgba } from 'src/app/shared/helpers/hex2rgba';
import { defaultChartOptions } from './defaultChartOptions';

@Component({
  selector: 'app-widget-quick-line-chart',
  templateUrl: './widget-quick-line-chart.component.html',
  styleUrls: ['./widget-quick-line-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [scaleInOutAnimation]
})
export class WidgetQuickLineChartComponent implements OnInit {

  @Input() value: string;
  @Input() label: string;
  @Input() tooltip: string;
  @Input() color: string;
  @Input() icon: string;
  @Input() series: ApexNonAxisChartSeries | ApexAxisChartSeries;

  options: ApexOptions = defaultChartOptions({
    chart: {
      type: 'area',
      height: 64
    }
  });

  showButton: boolean;

  hex2rgba = hex2rgba;

  constructor() { }

  ngOnInit() {
    this.options = defaultChartOptions({
      chart: {
        type: 'area',
        height: 64
      },
      colors: [this.color]
    });
  }

}

