import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Options } from 'ng5-slider';

@Component({
  selector: 'app-common-slider',
  templateUrl: './common-slider.component.html',
  styleUrls: ['./common-slider.component.scss']
})
export class CommonSliderComponent implements OnInit {

  @Input()
  sliderOptions: ISliderOption[];

  @Output()
  valuesChanged = new EventEmitter<number[]>();

  constructor() { }

  ngOnInit(): void {
  }

  userChangeEnd(e, idx) {
    const opt = this.sliderOptions[idx];
    opt.value = e.value;
    this.valuesChanged.emit(this.sliderOptions.map(x => x.value));
  }
}

export interface ISliderOption {
  name: string;
  title: string;
  value: number;
  defaultValue: number;
  options: Options;
}
