import { HttpClient } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './services/local-storage.service';
import { ToolbarService } from './services/toolbar.service';

export const util = {
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },
  hexToRGB,
  markFormGroupTouched,
  scrollToSmooth,
  hashCode
};

export const capitalize = (s) => {
  if (typeof s !== 'string') {
    return '';
  }
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export function translateLoaderFactory(http: HttpClient, moduleName: string) {
  return new TranslateHttpLoader(
    http,
    `${environment.tranlsationsBaseUrl}getTextData?page=${moduleName}&lang=`,
    ''
  );
}

export function financialYearFormatter(value, timestamp) {
  return 'w' + moment(timestamp).endOf('week').format('w\'YY');
}

export const transp = a => Object.keys(a[0]).map(c => {
  const ret = a.map(r => r[c]); ret.unshift(c);
  return ret;
});

export function initializeModuleTranslations(
  translateService: TranslateService,
  localStorageService: LocalStorageService,
  toolbarService: ToolbarService
) {
  const lang = localStorageService.getItem('currentLang');
  if (lang) {
    translateService.use(lang);
  } else {
    translateService.use('en');
  }
  toolbarService.languageChangedSubject.subscribe(val => {
    translateService.use(val.toString());
  });
}

export function hexToRGB(hex, alpha) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
}

function markFormGroupTouched(formGroup: FormGroup) {
  (Object as any).values(formGroup.controls).forEach(control => {
    control.markAsTouched();

    if (control.controls) {
      this.markFormGroupTouched(control);
    }
  });
}

function scrollToSmooth(querySelectorParent: string, querySelectorChild?: string) {
  const parent = document.querySelector(querySelectorParent);
  const child = querySelectorChild ? document.querySelector(querySelectorChild) : null;
  parent.scrollTo({
    top: child ? (child as HTMLElement).offsetHeight : 10000,
    left: 0,
    behavior: 'smooth'
  });
}

/**
 * Returns a hash code for a string.
 * (Compatible to Java's String.hashCode())
 *
 * The hash code for a string object is computed as
 *     s[0]*31^(n-1) + s[1]*31^(n-2) + ... + s[n-1]
 * using number arithmetic, where s[i] is the i th character
 * of the given string, n is the length of the string,
 * and ^ indicates exponentiation.
 * (The hash value of the empty string is zero.)
 *
 * @param s a string
 * @return a hash code value for the given string.
 */
function hashCode(s) {
  if (!s) {
    return 0;
  }
  let h = 0;
  const l = s.length;
  let i = 0;
  if (l > 0) {
    while (i < l) {
      // tslint:disable-next-line: no-bitwise
      h = (h << 5) - h + s.charCodeAt(i++) | 0;
    }
  }
  return h;
}
