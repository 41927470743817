import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberSpace'
})
export class NumberSpacePipe implements PipeTransform {

  // finds 3 consecutive digits in input value and puts space between
  // example: 10000 -> 10 000
  transform(value: any): string {
    return isNaN(+value)
      ? value
      : String(Math.floor(+value))
        .replace(
          /(?!^)(?=(?:\d{3})+$)/g,
          ' '
        );
  }

}
