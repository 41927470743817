import { Routes } from '@angular/router';
import { ContentLayoutComponent } from './layout/components/content-layout/content-layout.component';
import { FullLayoutComponent } from './layout/components/full-layout/full-layout.component';
import { AuthGuard } from './shared/guards/auth-guard.guard';
import { ModuleAccessGuard } from './shared/guards/module-access.guard';

export const routes: Routes = [
  {
    path: '', component: ContentLayoutComponent, data: { title: 'Full Views' }, children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
        canActivate: [AuthGuard]
      }, {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard],
        data: {
          moduleName: 'Dashboard'
        }
      },
      {
        path: 'editor',
        loadChildren: () => import('./editor/editor.module').then(m => m.EditorModule),
        canActivate: [AuthGuard],
        data: {
          moduleName: 'Editor'
        }
      },
      {
        path: 'product-monitoring',
        loadChildren: () => import('./product-monitoring/product-monitoring.module').then(m => m.ProductMonitoringModule),
        canActivate: [AuthGuard, ModuleAccessGuard],
        data: {
          moduleName: 'Consumer Feedback'
        }
      },
      {
        path: 'search-monitoring',
        loadChildren: () => import('./search-monitoring/search-monitoring.module').then(m => m.SearchMonitoringModule),
        canActivate: [AuthGuard, ModuleAccessGuard],
        data: {
          moduleName: 'Search Monitoring'
        }
      },
      {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
        canActivate: [AuthGuard, ModuleAccessGuard],
        data: {
          moduleName: 'Reports'
        }
      },
      {
        path: 'white-space',
        loadChildren: () => import('./white-space/white-space.module').then(m => m.WhiteSpaceModule),
        canActivate: [AuthGuard, ModuleAccessGuard],
        data: {
          moduleName: 'White Space Research'
        }
      },
      {
        path: 'new-launch-prediction',
        loadChildren: () => import('./new-launch-prediction/new-launch-prediction.module').then(m => m.NewLaunchPredictionModule),
        canActivate: [AuthGuard, ModuleAccessGuard],
        data: {
          moduleName: 'Prelaunch Prediction'
        }
      },
      {
        path: 'social-media',
        loadChildren: () => import('./social-media/social-media.module').then(m => m.SocialMediaModule),
        canActivate: [AuthGuard, ModuleAccessGuard],
        data: {
          moduleName: 'Social Media'
        }
      },
      {
        path: 'needs-analysis',
        loadChildren: () => import('./needs-analysis/needs-analysis.module').then(m => m.NeedsAnalysisModule),
        canActivate: [AuthGuard, ModuleAccessGuard],
        data: {
          moduleName: 'Needs Analysis'
        }
      },
      {
        path: 'existing-sku-prediction',
        loadChildren: () => import('./existing-sku-prediction/existing-sku-prediction.module').then(m => m.ExistingSkuPredictionModule),
        canActivate: [AuthGuard, ModuleAccessGuard],
        data: {
          moduleName: 'Existing SKU Prediction'
        }
      },
      {
        path: 'admin/companies',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        canActivate: [AuthGuard],
        data: {
          moduleName: 'User Management'
        }
      },
      {
        path: 'admin/data-management',
        loadChildren: () => import('./data-management/data-management.module').then(m => m.DataManagementModule),
        canActivate: [AuthGuard],
        data: {
          moduleName: 'Data Management'
        }
      }
    ]
  },
  {
    path: '', component: FullLayoutComponent, data: { title: 'Content Views' }, children: [
      {
        path: 'login',
        loadChildren: () => import('./auth/login/login.module').then(m => m.LoginModule)
      },
      {
        path: 'auth/onboard',
        loadChildren: () => import('./auth/register/register.module').then(m => m.RegisterModule)
      },
      {
        path: 'forgot-password',
        loadChildren: () => import('./auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
      },
      {
        path: 'auth/reset-password', // prefix 'auth' needded to be compatible with aspnet action url format
        loadChildren: () => import('./auth/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
      },
      {
        path: 'country-selection',
        loadChildren: () => import('./country-selection/country-selection.module').then(m => m.CountrySelectionModule),
        canActivate: [AuthGuard]
      },
    ]
  }
];
