import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, ViewChild, OnInit } from '@angular/core';
import { ApexAxisChartSeries, ApexOptions, ChartComponent } from 'ng-apexcharts';

@Component({
  selector: 'app-common-horizontal-bar-chart',
  templateUrl: './common-horizontal-bar-chart.component.html',
  styleUrls: ['./common-horizontal-bar-chart.component.scss']
})
export class CommonHorizontalBarChartComponent implements OnInit, OnChanges {

  @Input() series: ApexAxisChartSeries;

  @Input() categories: string[];

  @Input() yAxisTitle: string;
  @Input() xAxisTitle: '';

  @Input() animationEnabled = true;
  @Input() labelsEnabled = true;

  @Input() showPercentSign = false;

  @Input() isVertical = false;
  @Input() isDistributed = false;

  @Input() colors: string[] = ['#00B0F0', '#bfbfbf', '#CC0066'];

  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ApexOptions>;

  constructor(private cd: ChangeDetectorRef) {
    this.chartOptions = {
      series: [],
      chart: {
        type: 'bar',
        height: 350,
        stacked: false,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: true
        },
        animations: {
          enabled: this.animationEnabled
        }
      },
      dataLabels: {
        enabled: this.labelsEnabled,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          columnWidth: '40px',
        }
      },
      grid: {
        borderColor: '#F7F9FC',
        xaxis: {
          lines: {
            show: false,
          }
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        type: 'category',
        categories: [],
        title: {
          text: '',
          style: {
            fontFamily: 'Poppins',
            fontWeight: '400',
            fontSize: '14px',
            color: '#6A7080'
          }
        },
      },
      yaxis: [{
        title: {
          text: '',
          style: {
            fontFamily: 'Poppins',
            fontWeight: '400',
            fontSize: '14px',
            color: '#6A7080'
          }
        },
      }],
      legend: {
        show: false,
        horizontalAlign: 'left',
        position: 'top',
        fontFamily: 'Poppins',
        fontSize: '14px',
        offsetY: 16,
        height: 32,
        markers: {
          radius: 50
        },
        labels: {
          colors: '#BFBFBF'
        }
      },
      tooltip: {
        shared: true,
        x: {
          show: true,
        },
      },
      fill: {
        opacity: 1
      }
    };
  }

  ngOnInit() {
    const self = this;
    this.chartOptions.plotOptions.bar.horizontal = !this.isVertical;
    this.chartOptions.plotOptions.bar.distributed = this.isDistributed;
    this.chartOptions.colors = this.colors;
    this.chartOptions.dataLabels.formatter = (value) => {
      if (self.showPercentSign) {
        return value + '%';
      } else {
        return value + '';
      }
    };
    if (self.chart) {
      self.chart.updateOptions(self.chartOptions, false, true, true);
    }
    self.cd.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.series && changes.series.currentValue && changes.series.currentValue !== this.chartOptions.series)
      || (changes.categories && changes.categories.currentValue && changes.categories.currentValue !== this.categories)) {
      this.initChartData();
    }
  }

  initChartData() {
    const self = this;
    if (self.chart) {
      self.chartOptions.series = self.series;
      this.chartOptions.chart = Object.assign(this.chartOptions.chart, { animations: { enabled: this.animationEnabled } });
      this.chartOptions.dataLabels = Object.assign(this.chartOptions.dataLabels, { enabled: this.labelsEnabled });
      self.chart.updateOptions(self.chartOptions.series, false, true, true);

      this.chartOptions.xaxis.title.text = this.xAxisTitle;
      this.chartOptions.xaxis.categories = this.categories;
      this.chartOptions.yaxis[0].title.text = this.yAxisTitle;
      this.chart.updateOptions(this.chartOptions, true, true, true);
      self.cd.detectChanges();
    } else {
      setTimeout(() => {
        self.initChartData();
      }, 1000);
    }
  }

  toggleSerie(serieName: string) {
    this.chart.toggleSeries(serieName);
  }
}
