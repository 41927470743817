import { Component, OnInit, AfterViewInit, OnChanges, Input, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
import { TableColumn } from 'src/app/core/tableColumn';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialogRef } from '@angular/material/dialog';
import { fadeInUp400ms } from 'src/app/animations/fade-in-up.animation';
import { fadeOutUp400ms } from 'src/app/animations/fade-out-up.animation';
import { stagger40ms } from 'src/app/animations/stagger.animation';
import { PopupDialogComponent } from 'src/app/white-space/components/popup-dialog/popup-dialog.component';

@Component({
  selector: 'app-common-projects-table',
  templateUrl: './common-projects-table.component.html',
  styleUrls: ['./common-projects-table.component.scss'],
  animations: [
    fadeInUp400ms,
    fadeOutUp400ms,
    stagger40ms
  ]
})
export class CommonProjectsTableComponent implements AfterViewInit, OnChanges, OnInit {

  @Input() tableData: any[] = [];
  @Input() displayedColumns: string[] = [];
  @Input() columns: TableColumn<any>[] = [];
  @Input() showCreateSessionButton = true;
  @Input() showSelected = true;
  @Input() enabledClickedRow = false;
  @Input() searchPlaceholderText = 'Search your session';
  @Input() createButtonText = 'Create New Session';

  @Output() rowClicked = new EventEmitter();
  @Output() toggleCheck = new EventEmitter<SelectionModel<any>>();
  @Output() createButtonClicked = new EventEmitter();
  @Output() deleteTriggered = new EventEmitter<string[]>();

  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  searchCtrl = new FormControl();
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  popupDialogRef: MatDialogRef<PopupDialogComponent>;
  selectedRowsList: any;

  constructor(

  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tableData && changes.tableData.currentValue) {
      this.dataSource = new MatTableDataSource<any>();
      this.dataSource.data = this.tableData;
    }
  }

  ngOnInit(): void {
    if (this.searchCtrl) {
      this.searchCtrl.valueChanges.subscribe(value => this.onFilterChange(value));
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  onFilterChange(value: any) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  deleteItems() {
    this.deleteTriggered.emit(this.selection.selected.map(x => x.id));
  }

  // handler for row checkbox
  onToggleCheck(e, row) {
    this.selection.toggle(row);
    this.toggleCheck.emit(this.selection);
  }

  // handler for header cell checkbox (master toggle)
  onMasterToggleCheck(e) {
    this.masterToggle();
    this.toggleCheck.emit(this.selection);
  }
}

export interface CommonProjectListPage {
  rowClicked: (row: any) => void;
  createButtonClicked: () => void;
  deleteClicked: (ids: string[]) => void;
}
