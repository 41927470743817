import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoggedInUser } from '../auth/models/loggedInUser';
import { AccessService } from './services/access.service';
import { LocalStorageService } from './services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  navigationItemsSubject: BehaviorSubject<any> = new BehaviorSubject([]);
  currentCountryCode = 'gl'; // global country for JTI by default

  regularSidebarItems: NavigationItem[] = [{
    label: 'White Space Research',
    description: 'Identify new ideas',
    icon: 'nav_search',
    route: '/white-space'
  }, {
    label: 'Prelaunch Prediction',
    description: 'Predict your new product sales',
    icon: 'nav_send',
    route: '/new-launch-prediction'
  }, {
    label: 'Needs Analysis',
    description: 'Internal tool for attribute analysis',
    icon: 'nav_edit_square',
    route: '/needs-analysis'
  }, {
    label: 'Existing SKU Prediction',
    description: 'Forecast product performance',
    icon: 'nav_activity',
    route: '/existing-sku-prediction'
  }, {
    label: 'User Management',
    description: '',
    icon: 'nav_lightbulb',
    route: '/admin/companies'
  }, {
    label: 'Data Management',
    description: '',
    icon: 'nav_file',
    route: '/admin/data-management'
  }];

  get jtiSidebarItems() {
    return [{
      label: 'Dashboard',
      description: '',
      icon: 'nav_dashboard',
      route: `/dashboard/${this.currentCountryCode}`,
      countries: ['gl', 'ru', 'gb', 'it']
    }, {
      label: 'Consumer Feedback',
      description: '',
      icon: 'nav_comment',
      route: '/product-monitoring/social-listening',
      countries: ['gl', 'ru', 'gb', 'it']
    }, {
      label: 'Search Monitoring',
      description: '',
      icon: 'nav_search',
      route: `/search-monitoring/main/${this.currentCountryCode}`,
    }, {
      label: 'Social Media',
      description: '',
      icon: 'nav_star',
      route: '/social-media',
      countries: ['ru', 'gb', 'it', 'gl']
    },
    {
      label: 'Reports',
      description: '',
      icon: 'nav_file',
      route: '/reports'
    }];
  }

  get sidebarItems() {
    return [...this.regularSidebarItems, ...this.jtiSidebarItems];
  }

  constructor(
    private localStorageService: LocalStorageService,
    private accessService: AccessService
  ) {
  }

  updateCurrentCountryCode() {
    try {
      this.currentCountryCode = this.localStorageService.getItem('country') || 'ru';
    } catch (error) {
      console.log('No country selected');
    }
  }

  getSidebarNavigationItems(): Observable<NavigationItem[]> {
    const self = this;
    try {
      this.updateCurrentCountryCode();
      return this.accessService.getAvailableModules().pipe(
        map(modules => modules.map(x => x.moduleName)),
        map(moduleNames => this.sidebarItems
          .filter(x => !x.countries || x.countries.includes(self.currentCountryCode))
          .filter(x => moduleNames.includes(x.label))
        )
      );
    } catch (error) {
      return of([]);
    }
  }

  getDefaultRoute() {
    const currentUser = (JSON.parse(this.localStorageService.getItem('current_user')) as LoggedInUser);
    if (currentUser) {
      switch (currentUser.companyName) {
        case 'JTI': {
          return '/country-selection';
        }
        default: return '/';
      }
    }
    return '/';
  }
}

export interface NavigationItem {
  label: string;
  description?: string;
  icon: string;
  route: string;
  countries?: string[];
}
