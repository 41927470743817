import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NotificationsData, NotificationsService } from '../../notifications.service';
import { SidenavService } from '../../sidenav.service';

@Component({
  selector: 'app-notification-centre',
  templateUrl: './notification-centre.component.html',
  styleUrls: ['./notification-centre.component.scss']
})
export class NotificationCentreComponent implements OnInit {

  notificationsList: NotificationsData[];
  searchCtrl = new FormControl();

  page = 1;
  limit = 10;

  constructor(
    private sidenavService: SidenavService,
    private notificationsService: NotificationsService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.loadPosts(true);
  }

  onSubmitAllAsRead() {
    this.notificationsService.updateMarkAllAsRead().subscribe(e => {
      this.notificationsList.map(x => x.isRead);
    });
  }

  loadPosts(needToReload: boolean) {
    if (needToReload) {
      this.page = 1;
      this.notificationsList = [];
    } else {
      this.page++;
    }

    this.notificationsService.getNotifications(this.page, this.limit)
      .subscribe(d => {
        this.notificationsList = [...this.notificationsList, ...d];
        this.cd.detectChanges();
      });
  }

  closePanel() {
    this.sidenavService.close();
  }
}
