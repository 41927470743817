import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, Input,
  OnChanges, OnInit, SimpleChanges, ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ApexOptions, ChartComponent } from 'ng-apexcharts';
import { Subscription } from 'rxjs';
import { apexchartLocales } from 'src/app/core/apexchartsLocales';
import { TypedApexAxisChartSeries } from 'src/app/product-monitoring/models/typedAxisChartSeries';
import { util } from '../../util';

@Component({
  selector: 'app-common-plot-chart',
  templateUrl: './common-plot-chart.component.html',
  styleUrls: ['./common-plot-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonPlotChartComponent implements OnInit, OnChanges {

  @Input() chartData: TypedApexAxisChartSeries;
  @Input() yAxisTitle: string;
  @Input() animationEnabled = true;
  @Input() legendEnabled = true;
  @Input() yAxisMin: number = null;

  @Input() colors: string[] = ['#aeaeae', '#00B0F0', '#CC0066', '#197AAF'];

  chartDatasetsSubscription: Subscription;

  filtersForm = new FormGroup({
    chartType: new FormControl('cumulative', Validators.required)
  });

  @ViewChild('chart', { static: false }) chart: ChartComponent;
  public chartOptions: Partial<ApexOptions>;

  constructor(private cd: ChangeDetectorRef) {
    this.chartOptions = {
      series: [],
      chart: {
        height: 350,
        width: '100%',
        type: 'line',
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
        animations: {
          enabled: this.animationEnabled
        },
        defaultLocale: 'en',
        locales: apexchartLocales
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 3
      },
      yaxis: [{
        forceNiceScale: true,
        axisBorder: {
          show: true,
          color: '#78909C',
          offsetX: 0,
          offsetY: 0
        },
        axisTicks: {
          show: true,
          color: '#78909C',
          width: 6,
          offsetX: 0,
          offsetY: 0
        },
        title: {
          text: '',
          style: {
            fontFamily: 'Poppins',
            fontWeight: '400',
            fontSize: '14px',
            color: '#BFBFBF'
          }
        },
        labels: {
          formatter: (value) => {
            return value ? util.numberWithCommas(value) : value;
          }
        }
      }],
      xaxis: {
        type: 'datetime'
      },
      tooltip: {
        shared: true,
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {

          // setting header of tooltip equal to x value of highlighted point
          let tooltipMarkup = `
            <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
              ${moment(w.config.series[seriesIndex]?.data[dataPointIndex]?.x).format('DD/MM/YY')}
            </div>
          `;

          // here we copy series into another array, sort series by descending values of points of same x coordinate
          // for serie color - we find color index of serie in initial(unsorted) config
          [...w.config.series]
            .sort((a, b) => b?.data[dataPointIndex]?.y - a?.data[dataPointIndex]?.y)
            .forEach((serie) => {
              tooltipMarkup += `
                <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                  <span class="apexcharts-tooltip-marker" style="background-color: ${w.config.colors[w.config.series.indexOf(serie)]}"></span>
                  <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                    <div class="apexcharts-tooltip-y-group">
                      <span class="apexcharts-tooltip-text-label">
                        ${serie.name}:
                      </span>
                      <span class="apexcharts-tooltip-text-value">
                        ${serie?.data[dataPointIndex]?.y}
                        ${serie?.data[dataPointIndex]?.countReviews ? '(' + serie?.data[dataPointIndex]?.countReviews + ' reviews)' : ''}
                      </span>
                    </div>
                  </div>
                </div>
              `;
            });

          return tooltipMarkup;
        }
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        horizontalAlign: 'left',
        position: 'top',
        fontFamily: 'Poppins',
        fontSize: '14px',
        offsetY: 1,
        height: 32,
        markers: {
          radius: 50
        },
        labels: {
          colors: '#BFBFBF'
        }
      },
      grid: {
        borderColor: '#EFF1F9',
        strokeDashArray: 10,
        xaxis: {
          lines: {
            show: true,
          }
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      colors: [].concat.apply([], new Array(5).fill(['#aeaeae', '#00B0F0', '#CC0066', '#197AAF'])),
      annotations: {
        yaxis: [{
          y: 0,
          borderColor: '#6a7080',
          strokeDashArray: 0
        }]
      }
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.chartType && changes.chartType.currentValue) ||
      (changes.chartData && changes.chartData.currentValue && changes.chartData.currentValue !== this.chartOptions.series)) {
      this.initChartData();
    }
  }

  ngOnInit() {
    this.chartOptions.colors = this.colors;
    if (this.chart) {
      this.chart.updateOptions(this.chartOptions, false, true, true);
    }
  }

  initChartData() {
    const self = this;
    if (self.chart) {
      this.chartOptions.chart = Object.assign(this.chartOptions.chart, { animations: { enabled: this.animationEnabled } });
      if (this.yAxisTitle) {
        const newYaxisTitle = Object.assign(this.chartOptions.yaxis[0].title, { text: this.yAxisTitle });
        this.chartOptions = Object.assign(this.chartOptions, newYaxisTitle);
      }
      if (this.yAxisMin !== null) {
        const newYAxis = Object.assign(this.chartOptions.yaxis[0], { min: this.yAxisMin });
        this.chartOptions = Object.assign(this.chartOptions, { yaxis: [newYAxis] });
      }
      if (!this.legendEnabled) {
        this.chartOptions.legend.show = false;
      }
      this.chart.updateOptions(this.chartOptions, true, true, true);
      self.chartOptions.series = self.chartData.map(x => {
        return {
          name: x.name,
          data: x.data
        };
      });
      self.chart.updateSeries(self.chartOptions.series, true);
      self.cd.detectChanges();
    } else {
      setTimeout(() => {
        self.initChartData();
      }, 1000);
    }
  }

  toggleSerie(serieName: string) {
    this.chart.toggleSeries(serieName);
  }
}
